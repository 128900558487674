import React, { useContext, useState } from 'react';

import { OrderContext } from '../../../../context/OrderContext';
import Action from '../../../Action';
import Checkbox from '../../../Checkbox';
import IconSvg from '../../../../../../common/javascript/components/IconSvg/IconSvg';

import FaqIcon from '../../../../../../../icons/help.svg';
import MealIcon from '../../../../../../../icons/facilities/meal2.svg';
import NotMealIcon from '../../../../../../../icons/facilities/not-meal2.svg';
import OtherMealIcon from '../../../../../../../icons/facilities/other-meal2.svg';
import SquareIcon from '../../../../../../../icons/facilities/square.svg';
import SpecialRoomIcon from '../../../../../../../icons/special_room1.svg';

import Popup from '../../../Popup';
import usePopup from '../../../../../../hooks/use-popup';

import * as styles from './Service.module.scss';
import PaymentSchedule from '../PaymentSchedule/PaymentSchedule';
import { numberToMoneyWithoutCurrency } from '../../../../../../helpers/currency';
import AccommodateWithWish from '../../AccommodateWithWish';
import BedType from '../../BedType';
import SubOrder, { TYPE } from '../ServiceSelect/SubOrder';
import Slider from '../../../Slider';
import HotelInfo from '../../../../booking/components/BookingDescription/HotelInfo';
import RoomInfo from '../../../../booking/components/BookingDescription/RoomInfo';

const Service = () => {
  const {
    order,
    orderItems,
    subOrders,
    accommodateWithWish,
    requestBedType
  } = useContext(OrderContext);
  const [isPopupOpened, openPopup, closePopup] = usePopup();
  const [description, setDescription] = useState('');
  const [currentHotel, setCurrentHotel] = useState(null);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [blockText, setBlockText] = useState('');
  const [headerColor, setHeaderColor] = useState('');

  if (!order.id) return '';

  const iconFoodType = (foodTypeCode) => {
    if (foodTypeCode.includes(0)) return NotMealIcon;
    if (foodTypeCode.includes(20)) return MealIcon;

    return OtherMealIcon;
  };

  const handleShowHotelDescription = (hotel) => {
    setBlockText('');
    setHeaderColor('');
    setCurrentHotel(hotel);
    setCurrentRoom(null);
    setDescription(hotel.description);
    openPopup();
  };

  const handleShowRoomDescription = (hotel, room) => {
    setBlockText('');
    setHeaderColor('');
    setCurrentHotel(hotel);
    setCurrentRoom(room);
    setDescription(room.description);
    openPopup();
  };

  const handleShowDescription = (text) => {
    setBlockText('');
    setHeaderColor('');
    setCurrentHotel(null);
    setCurrentRoom(null);
    setDescription(text);
    openPopup();
  };

  const handleShowSubOrderDescription = (group) => {
    setCurrentHotel(null);
    setCurrentRoom(null);
    setDescription(group?.description);
    if (group.blockDate) {
      setBlockText(`Добавить и изменить услугу можно до <strong>${group.blockDate}</strong>`);
      setHeaderColor('purple');
    } else {
      setBlockText('');
      setHeaderColor('');
    }
    openPopup();
  };

  return (
    <>
      <ul className={styles.Service}>
        {orderItems.map(item => (
          <li key={`orderItem-${item.program.id}`}>
            <div className={styles.Service__row}>
              {item.program.is_apartment_program && (
                <div key={`wrap-${item?.program?.id}`} className={styles.Service__wrapHotel}>
                  {item.products.map(product => (
                    <div key={`apart-${product.id}`}>
                      <div className={styles.Service__wrapHead}>
                        <div>
                          {product?.hotel?.description && (
                            <Action
                              className={styles.Service__hotel_info_attr_link}
                              themes={['Link']}
                              onClick={() => handleShowHotelDescription(product?.hotel)}
                            >
                              {product?.hotel?.name}
                            </Action>
                          )}
                          {!product?.hotel?.description && product?.hotel?.name}
                        </div>
                      </div>
                      {/* <div className={styles.Service__hotel}>
                        <div className={styles.Service__hotel_image}>
                          <img
                            src={product?.hotel?.image}
                            alt=''
                          />
                        </div>
                        <div className={styles.Service__hotel_info}>
                          <div className={styles.Service__hotel_info_name}>{product?.hotel?.name}</div>
                          <div className={styles.Service__hotel_info_attr}>
                            <div className={styles.Service__hotel_info_attr_food}>
                              {product?.hotel?.foodType}
                            </div>
                            {product?.hotel?.description && (
                              <Action
                                className={styles.Service__hotel_info_attr_link}
                                to='/'
                                onClick={() => handleShowDescription(product?.hotel?.description)}
                              >
                                фото и описание ОТЕЛЯ
                              </Action>
                            )}
                          </div>
                        </div>
                      </div> */}
                      <div className={styles.Service__wrapRoom}>
                        <div className={styles.Service__room}>
                          <div className={styles.Service__room_info}>
                            <div className={styles.Service__room_info_name}>{product?.room?.name}</div>
                            <div className={styles.Service__room_info_attr}>
                              {product?.room?.foodType && (
                                <div className={styles.Service__room_info_attr_food}>
                                  {<IconSvg icon={iconFoodType(product?.room?.foodTypeCode)} />}
                                  {product?.room?.foodType}
                                </div>
                              )}
                              {product?.room?.square && (
                                <div className={styles.Service__room_info_attr_square}>
                                  <IconSvg icon={SquareIcon} />
                                  <div>
                                    {`${product?.room?.square} М`}
                                    <sup>2</sup>
                                  </div>
                                </div>
                              )}
                              {product?.room?.special && (
                                <div className={styles.Service__room_info_attr_square}>
                                  <IconSvg icon={SpecialRoomIcon} />
                                  <div>
                                    Особый
                                  </div>
                                </div>
                              )}
                            </div>
                            {product?.room?.description && (
                              <div
                                className={styles.Service__moreOrderItem}
                              >
                                <Action
                                  themes={['Link']}
                                  onClick={() => handleShowRoomDescription(product?.hotel, product?.room)}
                                >
                                  фото и описание номера
                                </Action>
                              </div>
                            )}
                          </div>
                          <div className={styles.Service__room_price}>
                            {product.cost > 0 && (
                              <>
                                <div className={styles.Service__cost}>{numberToMoneyWithoutCurrency(product.cost)}</div>
                                <div className={styles.Service__currency}>{product.currencySign}</div>
                              </>
                            )}
                          </div>
                        </div>
                        {Object.keys(accommodateWithWish).length > 0 && <AccommodateWithWish />}
                        {Object.keys(requestBedType).length > 0 && <BedType />}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {!item.program.is_apartment_program && (
                <>
                  <div className={styles.Service__wrapHead}>
                    <div>
                      {item?.program?.name}
                    </div>
                  </div>
                  <div key={`wrap-${item?.program?.id}`} className={styles.Service__wrapOptionsList}>
                    {item.products.map(product => (
                      <div key={`opt-${product.id}`} className={styles.Service__option}>
                        <div className={styles.Service__option_content}>
                          <Checkbox
                            label={`${product.name} - ${product.members_size} чел.`}
                            id={`id-option-checkbox-${product.id}`}
                            name={`option-checkbox-${product.id}`}
                            type='checkbox'
                            readOnly
                            checked
                          />
                          <div className={styles.Service__price}>
                            {product?.cost > 0 && (
                              <>
                                <div className={styles.Service__cost}>{numberToMoneyWithoutCurrency(product?.cost)}</div>
                                <div className={styles.Service__currency}>{product.currencySign}</div>
                              </>
                            )}
                            {product.description && (
                              <div
                                className={styles.Service__hint}
                                onClick={() => handleShowDescription(product.description)}
                              >
                                <IconSvg icon={FaqIcon} />
                              </div>
                            )}
                          </div>
                        </div>
                        {product.description && (
                          <div
                            className={styles.Service__more}
                          >
                            <Action
                              to='/'
                              onClick={() => handleShowDescription(product.description)}
                            >
                              подробнее
                            </Action>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </li>
        ))}

        {subOrders.map(item => (
          <li key={`orderSubOrderGroup-${item?.group?.id}`}>
            <div className={styles.Service__row}>
              <div className={styles.Service__wrapHead}>
                <div>
                  {item?.group?.description && (
                    <Action
                      themes={['Link']}
                      onClick={() => handleShowSubOrderDescription(item?.group)}
                    >
                      {item?.group?.name}
                    </Action>
                  )}
                  {!item?.group?.description && item?.group?.name}
                </div>
              </div>
              <div key={`wrap-${item?.group?.id}`} className={styles.Service__wrapSubOrdersList}>
                {item.SubOrderPeopleSelect.map(product => (
                  <SubOrder key={`subOrder-${product.subOrderName}`} product={product} type={TYPE.Service} />
                ))}
              </div>
            </div>
          </li>
        ))}
      </ul>

      <PaymentSchedule />

      <Popup
        show={isPopupOpened}
        onClose={closePopup}
        variant='medium'
        withHeader={blockText || false}
        header={blockText}
        headerColor={headerColor}
      >
        <div className={styles.Service__desc}>
          {(currentHotel && !currentRoom) && (
            <>
              <div className={styles.Service__slider}>
                <Slider contents={currentHotel.slider_content} />
              </div>
              <HotelInfo hotel={currentHotel} />
            </>
          )}
          {(currentRoom && currentHotel) && (
            <>
              <div className={styles.Service__slider}>
                <Slider contents={currentRoom.slider_content} />
              </div>
              <RoomInfo
                hotel={currentHotel}
                room={currentRoom}
                openHotelForm={() => handleShowHotelDescription(currentHotel)}
              />
            </>
          )}
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </Popup>
    </>
  );
};


Service.propTypes = {

};


export default Service;
